import { ApolloError } from '@sortlist-frontend/data-fetching/public';
import { Monitor } from '@sortlist-frontend/mlm';
import { useCaptureValidatePhoneOwnershipError } from '@sortlist-frontend/shared-components';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { cacheKey as membersCachekey } from '_components/Briefing/versions/EditMember/edit-member.repo';
import { PhoneValidationStatus } from '_types/generic';

type ValidatePhoneOwnershipArgs = {
  phoneOwnerId: string;
};

type ConfirmPhoneOwnershipArgs = {
  code: string;
  phoneOwnerId: string;
};

export const cacheKey = {
  isValidPhoneNumber: (value: string) => `/api/phone-number-validator?value=${value}`,
};

const urls = {
  isValidPhoneNumber: (value: string) => `/api/phone-number-validator?value=${encodeURIComponent(value)}`,
  validatePhoneOwnership: (encodedProjectUuid: string) =>
    `/api/proxy/v2/project/${encodedProjectUuid}/validate-phone-ownership`,
  confirmPhoneOwnership: (encodedProjectUuid: string) =>
    `/api/proxy/v2/project/${encodedProjectUuid}/confirm-phone-ownership`,
};

export const phoneNumberRepo = {
  isValidPhoneNumber: async (value: string): Promise<boolean> => {
    const res = await axios.get(urls.isValidPhoneNumber(value));
    return res?.data;
  },
  validatePhoneOwnership: async (
    encodedProjectUuid: string,
    args: ValidatePhoneOwnershipArgs,
  ): Promise<{ phoneValidationStatus: PhoneValidationStatus }> => {
    const res = await axios.post(urls.validatePhoneOwnership(encodedProjectUuid), args);
    return res?.data;
  },
  confirmPhoneOwnership: async (
    encodedProjectUuid: string,
    args: ConfirmPhoneOwnershipArgs,
  ): Promise<{ phoneValidationStatus: PhoneValidationStatus }> => {
    const res = await axios.post(urls.confirmPhoneOwnership(encodedProjectUuid), args);
    return res?.data;
  },
};

export function useIsValidPhoneNumber(value: string) {
  return useQuery({
    queryKey: [cacheKey.isValidPhoneNumber(value)],
    queryFn: () => {
      return phoneNumberRepo.isValidPhoneNumber(value);
    },
  });
}

export const useValidatePhoneOwnership = (encodedProjectUuid: string) => {
  const { captureError } = useCaptureValidatePhoneOwnershipError();
  return useMutation({
    mutationFn: (args: ValidatePhoneOwnershipArgs) => phoneNumberRepo.validatePhoneOwnership(encodedProjectUuid, args),
    onError: (error, variables) => {
      const errorMessageApolloLike = (error as any)?.response?.data as ApolloError;
      captureError(errorMessageApolloLike, variables);
    },
  });
};

export const useConfirmPhoneOwnership = (encodedProjectUuid: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (args: ConfirmPhoneOwnershipArgs) => phoneNumberRepo.confirmPhoneOwnership(encodedProjectUuid, args),
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: [membersCachekey.members(encodedProjectUuid)],
      });
    },
    onError: (error, variables) => {
      const errorMessage = (error as any)?.response?.data;
      // invalid_code is when the user provides an invalid code, so this is expected error
      if (errorMessage?.extensions?.details?.phone_owner?.[0]?.error !== 'invalid_code') {
        Monitor.captureMessage(`[useConfirmPhoneOwnershipApollo] unexpected error`, {
          extra: { extra: { error, variables } },
        });
      }
    },
  });
};
